import { Component } from "react";
import Ranking from "./Ranking";
import "./App.css";

class App extends Component {
  render() {
    return (
      <section className="container">
        <Ranking />
      </section>
    );
  }
}
export default App;
